import Login from "components/Login/Login.js";


function Home() {

  return (
    <Login />
  );
}

export default Home;
