import React, { useState } from "react";
import pb from "lib/pocketbase.js";
import "./Login.css";
import Slide from "../Slide/Slide.js";

export default function Login() {
  const [isVisible, setVisible] = useState(false);
  const [isLogged, setLogged] = useState(false);

  const recordVisit = async (id) => {
    const date = new Date();
    const isoString = date.toISOString();

    const data = {
      user: id,
      time: isoString,
    };

    const record = await pb.collection("visits").create(data);

    return;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const value = e.target[0].value;

    pb.collection("users")
      .getFirstListItem(`email="${value}"`)
      .then((res) => {
        let email = res.email.replace(/\s/g, "");

        if (email === value && res.active === true) {
          recordVisit(res.id);
          setLogged(true);
        } else {
          setVisible(true);
          console.log("ERROR !!!");
        }
      })
      .catch((error) => {
        console.log("ERROR !!!");
        setVisible(true);
      });
  };

  const handleClose = () => {
    console.log("CLOSING");
    setVisible(false);
  };
  return (
    <>
      {!isLogged ? (
        <main>
          <h1>Authorized Email</h1>
          <form className="login_form" onSubmit={handleLogin}>
            <input
              type="text"
              name="email "
              placeholder="guest@gmail.com"
              aria-label="Email"
              required
              autocomplete="off"
            />
            <button type="submit" className="submit_btn">
              Login
            </button>
          </form>
        </main>
      ) : (
        <Slide />
      )}
      <div
        className="dialog-container"
        style={isVisible ? { display: "block" } : { display: "none" }}
      >
        <dialog
          id="modal"
          className="modal"
          open={isVisible}
          onClick={handleClose}
        >
          <button id="closeModal" class="modal-close-btn" onClick={handleClose}>
            X
          </button>
          <p>Error !</p>
          <p>Please check back your input.</p>
        </dialog>
      </div>
    </>
  );
}
