import React, { useEffect, useRef } from "react";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/white.css";

import dg2 from "assets/imgs/digital_wallets/dg2.jpg";
import dg3 from "assets/imgs/digital_wallets/dg3.jpg";
import dg5 from "assets/imgs/digital_wallets/dg7.jpg";
import dg4 from "assets/imgs/digital_wallets/dg4.jpg";

import dg_merchant1 from "assets/imgs/digital_wallets/ezy_merchant1.jpg";
import dg_merchant2 from "assets/imgs/digital_wallets/ezy_merchant2.jpg";
import dg_merchant3 from "assets/imgs/digital_wallets/ezy_merchant3.jpg";
import dg_merchant4 from "assets/imgs/digital_wallets/ezy_merchant4.jpg";

import pt1 from "assets/imgs/proptech/pt1.jpg";
import pt2 from "assets/imgs/proptech/pt2.jpg";
import pt3 from "assets/imgs/proptech/pt3.jpg";

import ams1 from "assets/imgs/asset_management_solution/ams1.jpg";
import ams2 from "assets/imgs/asset_management_solution/ams2.jpg";
import ams3 from "assets/imgs/asset_management_solution/ams3.jpg";

import bot1 from "assets/imgs/crm_bot/bot1.jpg";

import mn1 from "assets/imgs/market_network/mn1.png";
import echelon from "assets/imgs/market_network/echelon.png";
import hero from "assets/imgs/market_network/3e_hero.png";

import mp1 from "assets/imgs/market_places/mp1.jpg";
import mp2 from "assets/imgs/market_places/mp2.jpg";
import mp3 from "assets/imgs/market_places/mp3.jpg";
import mp4 from "assets/imgs/market_places/mp4.jpg";

import ota1 from "assets/imgs/ota/ota1.jpg";
import ota2 from "assets/imgs/ota/ota2.jpg";
import ota4 from "assets/imgs/ota/ota4.jpg";
import ota5 from "assets/imgs/ota/ota5.jpg";
import ota6 from "assets/imgs/ota/ota6.jpg";
import ota7 from "assets/imgs/ota/ota7.jpg";

import ride1 from "assets/imgs/ride/ride1.png";
import ride2 from "assets/imgs/ride/ride2.png";
import ride3 from "assets/imgs/ride/ride3.png";
import ride4 from "assets/imgs/ride/ride4.png";

import kool1 from "assets/imgs/kool/kool1.png";
import kool2 from "assets/imgs/kool/kool2.png";
import kool3 from "assets/imgs/kool/kool3.png";
import kool4 from "assets/imgs/kool/kool4.png";

import charity1 from "assets/imgs/charity/charity1.png";
import charity2 from "assets/imgs/charity/charity2.png";
import charity3 from "assets/imgs/charity/charity3.png";
import charity4 from "assets/imgs/charity/charity4.png";

import stealth from "assets/imgs/stealth.png";
import puzzle from "assets/imgs/puzzle.png";
import more from "assets/imgs/apps.png";

import "./Slide.css";
import { Card } from "components/Card/Card.js";

export default function Slide() {
  const deckRef = useRef(null);

  useEffect(() => {
    // Prevents double initialization in strict mode
    if (deckRef.current) return;

    deckRef.current = new Reveal({
      transition: "convex",
      embedded: false,
      controlsTutorial: true,
      // other config options
      // autoAnimate: true,
    });

    deckRef.current.initialize();

    return () => {
      try {
        if (deckRef) {
          console.log("trying destroy");
          deckRef.destroy();
          deckRef = null;
        }
      } catch (e) {
        console.warn("Reveal.js destroy call failed.");
      }
    };
  }, []);

  return (
    // Your presentation is sized based on the width and height of
    // our parent element. Make sure the parent is not 0-height.
    <main>
      <div className="reveal">
        <div className="slides">
          <section>
            <h2 r-fit-text>
              Welcome to Software Volcano Agency (SVA), an advanced and
              innovative software delivery framework by{" "}
              <a href="https://softlab.uk" target="_blank" rel="noreferrer">
                SoftLab
              </a>
            </h2>
          </section>
          <section>
            <p r-fit-text>
              @SVA, you have at your fingertips a dedicated, distributed and
              experienced tech team in mission that have been working for &gt; 4
              years now in order to streamline and accelerate [30-90 working
              days] software platforms and services delivery ✔️
            </p>
          </section>
          <section>
            <p r-fit-text>You've got the audience, We've got the tech.</p>
            <p r-fit-text>The perfect partnership, right HERE, right NOW ✔️</p>
          </section>

          <section>
            <section>
              <h3>Our Ethos</h3>
              <p>
                Our ethos in software technologies revolves around the below
                quotes.
              </p>
              <p>👇🏿👇🏿👇🏿</p>
            </section>

            <section>
              <p>
                “Better and Perfect are the enemies of What-Is-Actually-Needed”
                <span className="author">— Alan Kay</span>
              </p>
              <p className="fragment fade-up">
                “Simplicity is a great virtue but it requires hard work to
                achieve it and education to appreciate it. And to make matters
                worse: complexity sells better”<span>Edsger W. Dijkstra</span>
              </p>
              <p className="fragment fade-up">
                “Security and Flexibility are inversely proportional”
                <span>— General observations</span>
              </p>
              <p className="fragment fade-up">
                “What makes GREAT products is CONTENT not PROCESS””
                <span>— Steve Jobs</span>
              </p>
            </section>
          </section>
          <section>
            <h3>Talk is cheap. Let us show you a glimpse of what we do. </h3>
          </section>
          <section>
            <section data-transition="slide">
              <h5 className="r-fit-text">Digital Wallet within Web3 context</h5>
              <h6>(Read | Write | Own)</h6>
              <div className="card-group">
                {/* <div class='mobile-phone'>
                                    <div class='brove'><span class='speaker'></span></div>
                                    <div class='screen'>
                                        <img className='screen_container' src={digitalWallet1} />

                                    </div>
                                </div> */}

                <Card className="card" source={dg2} />
                <Card className="card" source={dg3} />
                <Card className="card" source={dg5} />
                <Card className="card" source={dg4} />
              </div>
            </section>
            <section data-transition="slide">
              <div className="card-group">
                <Card className="card" source={dg_merchant1} />
                <Card className="card" source={dg_merchant2} />
                <Card className="card" source={dg_merchant3} />
                <Card className="card" source={dg_merchant4} />
              </div>
            </section>
          </section>

          <section data-transition="slide">
            <h5 className="r-fit-text">Sleek PropTech platforms</h5>
            <div className="card-group">
              <Card source={pt1} />
              <Card source={pt2} />
              <Card source={pt3} />
            </div>
          </section>
          <section data-transition="slide">
            <h5 className="r-fit-text">
              Revolutionary Asset Management solutions
            </h5>
            <div className="card-group">
              <Card source={ams1} />
              <Card source={ams2} />
              <Card source={ams3} />
            </div>
          </section>
          <section data-transition="slide">
            <h5 className="r-fit-text">
              Advanced CRM BOT interfaces with internal/external access.
            </h5>
            <h6>[Below an example of a BOT Telegram]</h6>
            <div className="card-group">
              <Card className="card" source={bot1} />
            </div>
          </section>

          <section data-transition="slide">
            <section data-transition="slide">
              <h5 className="r-fit-text">
                Market Networks, the ultimate successor of Marketplaces
              </h5>
              <div className="card-group">
                <img className="illustration" src={mn1} />
              </div>
            </section>
            <section data-transition="slide">
              <img className="illustration" src={echelon} />
            </section>
            <section data-transition="slide">
              <img className="illustration" src={hero} />
            </section>
          </section>
          <section data-transition="slide">
            <h5 className="r-fit-text">
              Curated marketplaces with top-notch onboarding processes
            </h5>
            <div className="card-group">
              <Card className="card" source={mp3} />
              <Card className="card" source={mp2} />
              <Card className="card" source={mp4} />
              <Card className="card" source={mp1} />
            </div>
          </section>
          <section data-transition="slide">
            <section>
              {" "}
              <h5 className="r-fit-text">
                Modern and refined Online Traveling Agency (OTA) platforms
              </h5>
              <div className="card-group">
                <Card className="card" source={ota2} />
                <Card className="card" source={ota4} />
                <Card className="card" source={ota5} />
              </div>
            </section>
            <section>
              <div className="card-group">
                <Card className="card" source={ota6} />
                <Card className="card" source={ota7} />
                <Card className="card" source={ota1} />
              </div>
            </section>
          </section>

          <section data-transition="slide">
            <h5>ENLIGHTENED FUNDRAISING & DONATION PLATFORM</h5>

            <div className="card-group">
              <Card className="card" source={charity1} />
              <Card className="card" source={charity2} />
              <Card className="card" source={charity3} />
              <Card className="card" source={charity4} />
            </div>
          </section>

          <section data-transition="slide">
            <h5>SOPHISTICATED RIDE HAILING & RENTING PLATFORM</h5>

            <div className="card-group">
              <Card className="card" source={ride1} />
              <Card className="card" source={ride2} />
              <Card className="card" source={ride3} />
              <Card className="card" source={ride4} />
            </div>
          </section>

          <section data-transition="slide">
            <h5>REFINED FOOD & DELIVERY ORDERING PLATFORM</h5>

            <div className="card-group">
              <Card className="card" source={kool1} />
              <Card className="card" source={kool2} />
              <Card className="card" source={kool3} />
              <Card className="card" source={kool4} />
            </div>
          </section>

          <section data-transition="slide">
            <h5>Disruptive Web3 solutions</h5>
            <p>in the making...</p>
            <img
              className="stealth"
              src={stealth}
              alt="image of a stealth plane"
            />
          </section>
          <section data-transition="slide">
            <h5>AI smart assistance solutions with verification modules</h5>
            <p>in the making...</p>
            <img
              className="stealth"
              src={stealth}
              alt="image of a stealth plane"
            />
          </section>
          <section data-transition="slide">
            <h5>AND MANY MORE UPON REQUEST</h5>
            <img className="more" src={more} alt="image of a stealth plane" />
          </section>

          <section className="contact-slide" data-transition="slide">
            <img
              className="puzzle"
              src={puzzle}
              alt="4 pieces of a puzzle fitting together"
            />
            <p>Do you see a fit ?</p>

            <h4>
              <a href="mailto:partners@softlab.uk">Contact Us</a>
            </h4>
          </section>
        </div>
      </div>
    </main>
  );
}
