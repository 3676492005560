import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "views/Home/Home.js";
import React, { useState, useEffect } from "react";
import pb from "lib/pocketbase.js";

import Icon from "components/Logo";

function App() {
  const [isActivated, setActivated] = useState(true);
  const [isDebug, setDebug] = useState(false);
  const [display, setDisplay] = useState(<h1 className="later">Loading...</h1>);

  useEffect(() => {
    pb.collection("appconf")
      .getFirstListItem('name="activated"')
      .then((res) => setActivated(res.status));

    pb.collection("appconf")
      .getFirstListItem('name="debug"')
      .then((res) => setDebug(res.status));
  }, []);

  useEffect(() => {
    switch (isActivated) {
      case true:
        setDisplay(
          <>
            <header className="app_header">
              <Icon width={200} />
            </header>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </BrowserRouter>
          </>
        );
        break;
      case false:
        setDisplay(<p className="later">Come back later please...</p>);
        break;
    }
  }, [isActivated]);

  return (
    <>
      {isDebug ? (
        <div className="debug">
          {" "}
          App Status :{isActivated ? "Active" : "Inactive"}
        </div>
      ) : null}

      {display}

      <main className="blocked">
        <Icon width={400} />
        <p>This website is only available to desktop screens.</p>
      </main>
    </>
  );
}

export default App;
