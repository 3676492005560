import PocketBase from "pocketbase";

let pb = new PocketBase();

if (process.env.NODE_ENV === "development") {
  pb = new PocketBase(process.env.REACT_APP_PB_URL);
}

pb.autoCancellation(false);

export default pb;
