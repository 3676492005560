import React from "react";

function Icon({ width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      version="1.1"
      viewBox="0 0 1280 1024"
      xmlSpace="preserve"
    >
      <g transform="translate(-13.005 .746)">
        <g transform="matrix(1.96928 0 0 1.96928 640.015 400.733)">
          <g transform="matrix(.3089 0 0 -.3089 38.432 -38.435)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2477.34 5484.04a51.679 51.679 0 0114.45-14.46c-2.8 1.87-5.47 4.03-7.95 6.5a51.91 51.91 0 00-6.5 7.96"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2484.565 -5476.81)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 45.538 -34.096)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2510.2 5461.98c-1.79.36-3.51 1.02-5.26 1.57 1.75-.55 3.44-1.21 5.26-1.57"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2507.57 -5462.765)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 59.489 -37.218)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2548.7 5469.57c2.91 1.93 5.62 4.13 8.07 6.6l-.09-.09a53.73 53.73 0 00-7.98-6.51"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2552.735 -5472.87)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 42.392 -35.278)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2500.63 5464.88c-2.24.93-4.36 2.17-6.49 3.42 2.11-1.24 4.21-2.48 6.49-3.42"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2497.385 -5466.59)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 28.835 -81.81)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2411.65 5672.59c0-28.41-23.07-51.48-51.49-51.48 59.89 0 108.57-48.72 108.57-108.61 0-.72.18-1.39.21-2.11-.56 13.88 4.3 27.91 14.9 38.53l62.99 62.98c-35.66 66.69-105.94 112.18-186.67 112.18 28.42 0 51.49-23.07 51.49-51.49"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2453.495 -5617.235)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 35.281 -42.392)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2476.09 5486.35c-1.27 2.13-2.52 4.28-3.45 6.54.95-2.3 2.19-4.42 3.45-6.54"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2474.365 -5489.62)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 34.11 -45.496)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2471.41 5496.86c-.6 1.87-1.29 3.71-1.67 5.62.38-1.95 1.07-3.77 1.67-5.62"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2470.575 -5499.67)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 56.545 -35.295)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2546.55 5468.41c-2.18-1.3-4.38-2.58-6.69-3.53 2.35.97 4.53 2.23 6.69 3.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2543.205 -5466.645)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 53.4 -34.103)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2535.73 5463.59c-1.8-.57-3.58-1.24-5.42-1.61 1.88.38 3.62 1.04 5.42 1.61"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2533.02 -5462.785)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 -32.673 -49.457)">
            <path
              fill="#6644a5"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2308.63 5352.36c0 28.46 23.07 51.53 51.53 51.53-59.93 0-108.6 48.72-108.6 108.61s48.67 108.61 108.6 108.61c-28.46 0-51.53 23.07-51.53 51.48 0 28.42 23.07 51.49 51.53 51.49-116.68 0-211.58-94.9-211.58-211.58 0-116.69 94.9-211.59 211.58-211.59-28.46 0-51.53 23.04-51.53 51.45"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2254.37 -5512.495)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 -.002 -98.91)">
            <path
              fill="#39469e"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2308.63 5672.59c0-28.41 23.07-51.48 51.53-51.48 28.42 0 51.49 23.07 51.49 51.48 0 28.42-23.07 51.49-51.49 51.49-28.46 0-51.53-23.07-51.53-51.49"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2360.14 -5672.595)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 131.587 49.452)">
            <path
              fill="#ed1e24"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2680.35 5403.89c28.46 0 51.49-23.07 51.49-51.53 0-28.41-23.03-51.45-51.49-51.45 59.85 0 108.61-48.71 108.61-108.6 0-59.89-48.76-108.65-108.61-108.65 28.46 0 51.49-23.03 51.49-51.45 0-28.46-23.03-51.53-51.49-51.53 116.69 0 211.59 94.95 211.59 211.63 0 116.64-94.9 211.58-211.59 211.58"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2786.145 -5192.285)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 -131.58 49.452)">
            <path
              fill="#6cc62e"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M1988.44 5032.21c0 28.42 23.07 51.45 51.54 51.45-59.94 0-108.65 48.76-108.65 108.65 0 59.89 48.71 108.6 108.65 108.6-28.47 0-51.54 23.04-51.54 51.45 0 28.46 23.07 51.53 51.54 51.53-116.73 0-211.63-94.94-211.63-211.58 0-116.68 94.9-211.63 211.63-211.63-28.47 0-51.54 23.07-51.54 51.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-1934.165 -5192.285)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 -14.936 24.72)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2091.42 5352.36c0-28.41-23.07-51.45-51.44-51.45 58.68 0 85.93-27.25 123.67-64.99 42.37-42.37 95.14-95.1 196.51-95.1h171.95c28.42 0 51.49 23.03 51.49 51.49s-23.07 51.49-51.49 51.49h-171.95c-58.72 0-85.98 27.25-123.71 64.95-42.41 42.37-95.15 95.14-196.47 95.14 28.37 0 51.44-23.07 51.44-51.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2311.79 -5272.355)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 -98.91 -.006)">
            <path
              fill="#25a444"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M1988.44 5352.36c0-28.41 23.07-51.45 51.54-51.45 28.37 0 51.44 23.04 51.44 51.45 0 28.46-23.07 51.53-51.44 51.53-28.47 0-51.54-23.07-51.54-51.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2039.93 -5352.4)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 49.456 -.006)">
            <path
              fill="#ed1e24"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2628.86 5352.36c0 28.46 23.03 51.53 51.49 51.53h-320.19c28.42 0 51.49-23.07 51.49-51.53 0-28.41-23.07-51.45-51.49-51.45h320.19c-28.46 0-51.49 23.04-51.49 51.45"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2520.255 -5352.4)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 -.002 -.006)">
            <path
              fill="#712e32"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2411.65 5352.36c0 28.46-23.07 51.53-51.49 51.53-28.46 0-51.53-23.07-51.53-51.53 0-28.41 23.07-51.45 51.53-51.45 28.42 0 51.49 23.04 51.49 51.45"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2360.14 -5352.4)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 98.908 -.006)">
            <path
              fill="#ed1e24"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2731.84 5352.36c0 28.46-23.03 51.53-51.49 51.53s-51.49-23.07-51.49-51.53c0-28.41 23.03-51.45 51.49-51.45s51.49 23.04 51.49 51.45"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2680.35 -5352.4)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 47.904 -33.698)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2520.26 5460.97c-3.45 0-6.8.37-10.06 1.01 3.32-.66 6.68-1.01 10.06-1.01"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2515.23 -5461.475)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 57.91 -36.02)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2548.7 5469.57c-.68-.44-1.45-.74-2.15-1.16.71.42 1.46.71 2.15 1.16"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2547.625 -5468.99)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 44.06 -34.544)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2504.94 5463.55c-1.43.45-2.93.76-4.31 1.33 1.41-.58 2.88-.88 4.31-1.33"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2502.785 -5464.215)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 51.01 -33.698)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2530.31 5461.98c-3.26-.64-6.61-1.01-10.05-1.01 3.37 0 6.73.35 10.05 1.01"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2525.285 -5461.475)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 41.027 -36.004)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2494.14 5468.3c-.76.46-1.6.79-2.35 1.28.76-.5 1.58-.82 2.35-1.28"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2492.965 -5468.94)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 54.874 -34.55)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2539.86 5464.88c-1.32-.55-2.75-.85-4.13-1.29 1.38.44 2.79.73 4.13 1.29"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2537.795 -5464.235)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 36.007 -41.025)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2477.34 5484.04c-.48.73-.81 1.56-1.25 2.31.44-.76.75-1.57 1.25-2.31"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2476.715 -5485.195)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 33.73 -47.586)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2469.74 5502.48c-.51 2.56-.69 5.23-.8 7.91.11-2.64.28-5.3.8-7.91"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2469.34 -5506.435)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 86.238 -76.526)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2556.77 5476.17l160 160.01c20.09 20.13 20.09 52.73 0 72.83-20.14 20.1-52.7 20.1-72.84 0l-97.1-97.11c15.86-29.66 24.92-63.48 24.92-99.4 0-14.18-5.73-27.01-14.98-36.33"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2639.334 -5600.127)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 34.558 -44.015)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2472.64 5492.89c-.53 1.27-.81 2.65-1.23 3.97.42-1.32.7-2.68 1.23-3.97"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2472.025 -5494.875)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 49.477 -56.852)">
            <path
              fill="#1f9cd1"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2468.94 5510.39c.11-2.68.29-5.35.8-7.91.38-1.91 1.07-3.75 1.67-5.62.42-1.32.7-2.7 1.23-3.97.93-2.26 2.18-4.41 3.45-6.54.44-.75.77-1.58 1.25-2.31a51.91 51.91 0 016.5-7.96c2.48-2.47 5.15-4.63 7.95-6.5.75-.49 1.59-.82 2.35-1.28 2.13-1.25 4.25-2.49 6.49-3.42 1.38-.57 2.88-.88 4.31-1.33 1.75-.55 3.47-1.21 5.26-1.57 3.26-.64 6.61-1.01 10.06-1.01 3.44 0 6.79.37 10.05 1.01 1.84.37 3.62 1.04 5.42 1.61 1.38.44 2.81.74 4.13 1.29 2.31.95 4.51 2.23 6.69 3.53.7.42 1.47.72 2.15 1.16a53.73 53.73 0 017.98 6.51l.09.09c9.25 9.32 14.98 22.15 14.98 36.33 0 35.92-9.06 69.74-24.92 99.4l-62.99-62.98c-10.6-10.62-15.46-24.65-14.9-38.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2520.323 -5536.435)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 .006 98.91)">
            <path
              fill="#f4a80e"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2628.86 5032.21c0 28.42 23.03 51.45 51.49 51.45h-640.37c28.37 0 51.44-23.03 51.44-51.45 0-28.46-23.07-51.53-51.44-51.53h640.37c-28.46 0-51.49 23.07-51.49 51.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2360.165 -5032.17)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 98.908 98.91)">
            <path
              fill="#e91d24"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2731.84 5032.21c0 28.42-23.03 51.45-51.49 51.45s-51.49-23.03-51.49-51.45c0-28.46 23.03-51.53 51.49-51.53s51.49 23.07 51.49 51.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2680.35 -5032.17)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 -98.91 98.91)">
            <path
              fill="#72902f"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2091.42 5032.21c0 28.42-23.07 51.45-51.44 51.45-28.47 0-51.54-23.03-51.54-51.45 0-28.46 23.07-51.53 51.54-51.53 28.37 0 51.44 23.07 51.44 51.53"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2039.93 -5032.17)"
            ></path>
          </g>
          <g transform="matrix(.3089 0 0 -.3089 99.05 49.444)">
            <path
              fill="#52b7e2"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeWidth="1"
              d="M2732.32 5192.31c0 28.46-23.07 51.49-51.52 51.49-28.42 0-51.5-23.03-51.5-51.49 0-28.42 23.08-51.49 51.5-51.49 28.45 0 51.52 23.07 51.52 51.49"
              opacity="1"
              paintOrder="stroke"
              transform="translate(-2680.81 -5192.31)"
            ></path>
          </g>
        </g>
        <text
          xmlSpace="preserve"
          style={{ InkscapeFontSpecification: "exo2" }}
          x="360.713"
          y="779.698"
          fill="#39469e"
          fillOpacity="1"
          fontFamily="exo2"
          fontSize="160"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
        >
          <tspan
            x="360.713"
            y="779.698"
            style={{}}
            fill="#39469e"
            fillOpacity="1"
            fontFamily="Exo 2"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            SoftLab
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default Icon;
